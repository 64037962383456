/* eslint-disable import/no-anonymous-default-export */
import { SET_SINGLE_BENEFICIARY_LOADING, SINGLE_BENEFICIARY, SINGLE_BENEFICIARY_ERROR, CLEAR_SINGLE_BENEFICIARY, CLEAR_SINGLE_BENEFICIARY_ERROR } from '../actions/types';

const initialState = { single_beneficiary: null, single_beneficiary_loading: false, single_beneficiary_error: null }

export default function (state = initialState, action){
    switch(action.type){
        case SET_SINGLE_BENEFICIARY_LOADING:
            return {
                ...state, single_beneficiary_loading: true, single_beneficiary_error: null
            }
        case SINGLE_BENEFICIARY:
            return {
                ...state, single_beneficiary: action.payload, single_beneficiary_loading: false, single_beneficiary_error: null
            }
        case SINGLE_BENEFICIARY_ERROR:
            return {
                ...state, single_beneficiary_error: action.payload, single_beneficiary_loading: false
            }
        case CLEAR_SINGLE_BENEFICIARY:
            return {
                ...state, single_beneficiary: null, single_beneficiary_loading: false, single_beneficiary_error: null
            }
        case CLEAR_SINGLE_BENEFICIARY_ERROR:
            return {
                ...state, single_beneficiary_error: null, single_beneficiary_loading: false
            }
        default: return state
    }
}