/* eslint-disable import/no-anonymous-default-export */
import {CLEAR_INITIATE_PAYMENT, CLEAR_INITIATE_PAYMENT_ERROR, INITIATE_PAYMENT_LOADING, INITIATE_PAYMENT_ERROR, INITIATE_PAYMENT} from '../actions/types';

const initialState = {
    initiate_payment_data: null,
    initiate_payment_loading: false,
    initiate_payment_error: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_INITIATE_PAYMENT:
            return {
                ...state, 
                initiate_payment_data: null,
                initiate_payment_loading: false,
                initiate_payment_error: null
            }
        case CLEAR_INITIATE_PAYMENT_ERROR:
            return {
                ...state, 
                initiate_payment_loading: false,
                initiate_payment_error: null
            }
        case INITIATE_PAYMENT_LOADING:
            return {
                ...state, 
                initiate_payment_loading: true,
                initiate_payment_error: null
            }
        case INITIATE_PAYMENT_ERROR:
            return {
                ...state, 
                initiate_payment_loading: false,
                initiate_payment_error: action.payload
            }
        case INITIATE_PAYMENT:
            return {
                ...state, 
                initiate_payment_data: action.payload,
                initiate_payment_loading: false,
                initiate_payment_error: null
            }
        default: return state
    }
}