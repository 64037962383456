import React from "react";

const ButtonLoader = (props) => {
  return (
    <span
      className={`btn text-center py-2 ${props.block && "btn-block"} ${
        props.bg
          ? props.bg + " " + (props.color || "textBlue")
          : "bgBlue text-white"
      } px-4 py-1 ${props.floatright && "float-end"}`}
    >
      <div
        className="spinner-border text-white my-0"
        role="status"
        style={{ width: "20px", height: "20px", borderWidth: "2px" }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </span>
  );
};

export default ButtonLoader;
