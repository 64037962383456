// import React from 'react';
import { useHistory } from "react-router-dom";

const useInternalLinks = (props) => {
	const history = useHistory();

	const accountLinks = [
		{
			label: "My Profile",
			command: () => {
				history.push("/profile");
			},
		},
		{
			label: "Change Password",
			command: () => {
				history.push("/change-password");
			},
		},
	];

	const upgradeLinks = [
		{
			label: "Upgrade to Limited KYC",
			command: () => {
				history.push("/upgrade-limited");
			},
		},
		{
			label: "Upgrade to Unlimited KYC",
			command: () => {
				history.push("/upgrade-unlimited");
			},
		},
	];

	const upgradeLimited = [
		{
			label: "Upgrade to Limited KYC",
			command: () => {
				history.push("/upgrade-limited");
			},
		},
	];

	const upgradeUnlimited = [
		{
			label: "Upgrade to Unlimited KYC",
			command: () => {
				history.push("/upgrade-unlimited");
			},
		},
	];

	const portalLinks = [
		{
			label: "Send Money",
			command: () => {
				history.push("/send-money");
			},
		},
		{
			label: "My Limits",
			command: () => {
				history.push("/upgrade-account");
			},
		},
		{
			label: "Transactions",
			command: () => {
				history.push("/transactions");
			},
		},
		{
			label: "Beneficiaries",
			command: () => {
				history.push("/beneficiaries");
			},
		},
		{
			label: "Paytumi",
			command: () => {
				
			},
		},
	];

	const allInternalLinks = portalLinks.concat(accountLinks);

	return {
		accountLinks,
		portalLinks,
		allInternalLinks,
		upgradeLinks,
		upgradeUnlimited,
		upgradeLimited,
	};
};

export default useInternalLinks;
