import React from 'react';

const CheckBox = ({ name, label, value, ...props }) => {
    return (
        <>
            <div className="form-check">
                <input className={`form-check-input`}
                    type="checkbox"
                    id={name}
                    {...props}
                />
                <label htmlFor={name} className="form-check-label">
                    {label || value}
                </label>
            </div>
        </>
    )
}

export default CheckBox;
