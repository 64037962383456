import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { TextField, PageLoader, Error } from "components";
import { AuthLayout } from "layouts";
import * as actions from "store/actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "pages/Beneficiaries/UpdateBeneficiary.css";

const ResetPasswordLink = (props) => {
	const {
		uioptions,
		resetPassword,
		clearResetPasswordError,
		clearUIoptionsError,
		clearResetPassword,
	} = props;

	const { rp_data, rp_loading, rp_error } = props.resetPasswordReducer;

	useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Reset Link";
		return () => {
			subscribe = false;
		};
	}, []);

	const [resetType, setResetType] = useState("mobile");

	const initialValues = {
		mobile: "",
		email: "",
	};

	useEffect(() => {
		const time_id = setInterval(() => {
			rp_error && clearResetPasswordError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [rp_error, clearResetPasswordError]);

	const schemaObject = {
		// mobile: Yup.string().required().label("Mobile Number"),
		// email: Yup.string().email("Invalid email address").required("Email address is required"),
	};
	if (resetType === "mobile") schemaObject.mobile = Yup.string().required().label("Mobile Number");
	if (resetType === "email")
		schemaObject.email = Yup.string()
			.email("Invalid email address")
			.required("Email address is required");

	const resetPasswordSchema = Yup.object().shape(schemaObject);

	const handleResetPassword = (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
		const data = {
			type: resetType,
		};
		if (resetType === "email") data.email = values.email;
		if (resetType === "mobile") data.mobile = `+${values.mobile}`;
		resetPassword(data);
	};

	React.useEffect(() => {
		clearResetPasswordError();
		clearResetPassword();
		return () => {
			clearResetPasswordError();
			clearResetPassword();
		};
	}, [clearResetPasswordError, clearResetPassword]);

	return (
		<AuthLayout
			uioptions={uioptions}
			clearUIoptionsError={clearUIoptionsError}
			pagetitle="RESET PASSWORD"
		>
			{rp_loading && <PageLoader />}

			<Formik
				//   innerRef={ref}
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={resetPasswordSchema}
				onSubmit={handleResetPassword}
			>
				{(formik) => {
					const {
						values,
						handleSubmit,
						errors,
						handleBlur,
						isValid,
						dirty,
						setFieldValue,
						setErrors,
					} = formik;
					return (
						<form onSubmit={handleSubmit} className="form-signin mt-3" autoComplete="off">
							<p className="fw-bold">Forgot Password?</p>
							<p className="mt-4">
								Don’t worry, just enter the mobile number you registered with and we send
								you a new password
							</p>

							{rp_error &&
								rp_error.map((err) => <Error key={err.error} error={err.error} />)}

							{rp_data && rp_data.status === "SUCCESS" && (
								<div className="alert alert-success" role="alert">
									{rp_data.result.message}
								</div>
							)}

							<div className="row">
								{resetType === "mobile" ? (
									<div className="form-group mobile-input-container">
										<label>Mobile Number*</label>
										<PhoneInput
											name="mobile"
											country={"zw"}
											value={values.mobile}
											onChange={(mobile) => setFieldValue("mobile", mobile)}
											onBlur={handleBlur}
											placeholder=""
											containerClass={`phone-input`}
											autoFormat={false}
										/>
										{errors.mobile && (
											<span className="form-text small text-danger">
												{errors.mobile}
											</span>
										)}
									</div>
								) : (
									<>
										<div className="form-group mobile-input-container">
											<TextField label="Email*" name="email" />
										</div>
									</>
								)}

								<strong
									align="center"
									onClick={() => {
										if (resetType === "email") {
										}
										setErrors({});
										setResetType(resetType === "email" ? "mobile" : "email");
									}}
									style={{ cursor: "pointer" }}
								>
									Reset using {resetType === "email" ? "Mobile Number" : "Email"}
								</strong>

								<div className="d-grid gap-2 mt-3 mb-3">
									<button
										disabled={!(dirty && isValid)}
										className="btn btn-primary bgBlue"
										type="submit"
									>
										<i className="pi pi-lock"></i> Reset Password
									</button>
									<Link
										to="/"
										style={{
											textDecoration: "none",
											color: "#3B366F",
										}}
									>
										<strong>Back to Login</strong>
									</Link>
								</div>

								<br />
								<br />
							</div>
						</form>
					);
				}}
			</Formik>
		</AuthLayout>
	);
};

ResetPasswordLink.propTypes = {
	authorization: PropTypes.object.isRequired,
	login: PropTypes.func.isRequired,
	uioptions: PropTypes.object.isRequired,
	getUIoptions: PropTypes.func.isRequired,
	clearLoginError: PropTypes.func.isRequired,
	resetPassword: PropTypes.func.isRequired,
	clearResetPasswordError: PropTypes.func.isRequired,
	clearUIoptionsError: PropTypes.func.isRequired,
	clearResetPassword: PropTypes.func.isRequired,
	resetPasswordReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	authorization: state.authorization,
	uioptions: state.uioptions,
	resetPasswordReducer: state.resetPasswordReducer,
});

export default connect(mapStateToProps, actions)(ResetPasswordLink);
