import {
	ButtonLoader,
	ContactDetails,
	Error,
	Footer,
	TextFieldTwo,
	UnsignedHeader,
} from "components";
import React, { useEffect } from "react";
import SignedHeader from "components/SignedHeader/SignedHeader";
import { Formik } from "formik";
import * as Yup from "yup";
import RegularButton from "components/Buttons/RegularButton";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

import SessionTimeout from "utils/SessionTimeout";

const ChangePassword = (props) => {
	const { changePassword, clearChangePasswordError, uioptions, clearChangePassword } = props;

	const { cp_data, cp_loading, cp_error } = props.changePasswordReducer;

	const initialValues = {
		current_password: "",
		new_password: "",
		confirm_password: "",
	};

	const { user } = props.authorization;

	useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Change Password";
		return () => {
			subscribe = false;
		};
	}, []);

	useEffect(() => {
		const time_id = setInterval(() => {
			cp_error && clearChangePasswordError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [cp_error, clearChangePasswordError]);

	// clearChangePassword();

	useEffect(() => {
		const time_id = setInterval(() => {
			cp_data && clearChangePassword();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [cp_data, clearChangePassword]);

	const changePasswordSchema = Yup.object().shape({
		current_password: Yup.string().required().label("Current Password"),
		new_password: Yup.string()
			.matches(/([A-Z])/, "Invalid password, at least 1 capital letter expected")
			.min(8, "Invalid password. It should have more than 7 characters")
			.matches(/([0-9])/, "Invalid password, at least 1 numeric value expected")
			.required("Password is required"),
		confirm_password: Yup.string().when("new_password", {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf([Yup.ref("new_password")], "Both passwords need to be the same"),
		}),
	});

	const submitForm = (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
		const data = {
			current_password: values.current_password,
			new_password: values.new_password,
		};
		changePassword(data);
	};

	let content = (
		<Formik
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={changePasswordSchema}
			onSubmit={submitForm}
		>
			{(formik) => {
				const {
					values,
					handleChange,
					handleSubmit,
					errors,
					touched,
					handleBlur,
					isValid,
					dirty,
				} = formik;
				return (
					<form onSubmit={handleSubmit} className="form-signin mt-3 " autoComplete="off">
						{cp_data && cp_data.status === "SUCCESS" ? (
							<div className="alert alert-success" role="alert">
								Password Changed Successfully
							</div>
						) : (
							cp_error && cp_error.map((err) => <Error key={err.error} error={err.error} />)
						)}

						<div className="row">
							<div className="col-md-12">
								<TextFieldTwo
									name="current_password"
									label="Current Password*"
									placeholder="Current Password"
									value={values.current_password}
									onChange={handleChange}
									onBlur={handleBlur}
									autoComplete="off"
									type="password"
								/>
								{errors.current_password && touched.current_password && (
									<span className="form-text small text-danger">
										{errors.current_password}
									</span>
								)}
							</div>

							<div className="col-md-6">
								<TextFieldTwo
									name="new_password"
									label="New Password*"
									placeholder="New Password"
									value={values.new_password}
									onChange={handleChange}
									onBlur={handleBlur}
									autoComplete="off"
									type="password"
								/>
								{errors.new_password && touched.new_password && (
									<span className="form-text small text-danger">
										{errors.new_password}
									</span>
								)}
							</div>

							<div className="col-md-6">
								<TextFieldTwo
									name="confirm_password"
									label="Confirm Password"
									placeholder="Confirm Password*"
									value={values.confirm_password}
									onChange={handleChange}
									onBlur={handleBlur}
									autoComplete="off"
									type="password"
								/>
								{errors.confirm_password && touched.confirm_password && (
									<span className="form-text small text-danger">
										{errors.confirm_password}
									</span>
								)}
							</div>
							<br />
							<div className="col-md-12" style={{ marginTop: 20 }}>
								{cp_loading ? (
									<ButtonLoader floatright={true} />
								) : (
									<RegularButton
										value="Save"
										type="submit"
										floatright={true}
										disabled={!(dirty && isValid)}
									/>
								)}
							</div>

							<br />
							<br />
						</div>
					</form>
				);
			}}
		</Formik>
	);

	return (
		<div className="vh-100">
			<SessionTimeout />
			<div style={{ minHeight: "16%" }}>
				{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
			</div>

			<div className="py-0 py-sm-3" style={{ minHeight: "70%" }}>
				<div className="container">
					<div className="row">
						<div className="col-sm-5 col-md-4 col-lg-3 order-last order-sm-first mt-2 mt-sm-0">
							<ContactDetails />
						</div>
						<div className="col-sm-7 offset-md-1 col-md-6  offset-lg-1 col-lg-6">
							<div>
								<div className="alert alert-warning" role="alert">
									Fill in all mandatory (*) fields.
								</div>
								<h5 className="fw-bold">Change Password</h5>
								{content}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div style={{ minHeight: "14%" }}>
				<Footer user={user} uioptions={uioptions} />
			</div>
		</div>
	);
};

ChangePassword.propTypes = {
	changePassword: PropTypes.func.isRequired,
	clearChangePasswordError: PropTypes.func.isRequired,
	clearChangePassword: PropTypes.func.isRequired,
	changePasswordReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	changePasswordReducer: state.changePasswordReducer,
	uioptions: state.uioptions,
	authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(ChangePassword);
