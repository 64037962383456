import React from "react";
import { Error, Footer, PageLoader, SignedHeader, UnsignedHeader } from "components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import SessionTimeout from "utils/SessionTimeout";

const UpgradeKyc = (props) => {
	const { user } = props.authorization;
	const { uioptions, getLimits, clearGetLimitsError, getProfile } = props;

	const { limits, limits_loading, limits_error } = props.getLimitsReducer;
	const { profile, profile_loading } = props.myProfile;

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | KYC Limits";
		return () => {
			subscribe = false;
		};
	}, []);

	React.useEffect(() => {
		getLimits();
	}, [getLimits]);

	React.useEffect(() => {
		getProfile("profile");
	}, [getProfile]);

	React.useEffect(() => {
		setInterval(() => {
			limits_error && clearGetLimitsError();
		}, 12000);
	}, [limits_error, clearGetLimitsError]);

	const displayLimits = (type, slot, amount) => {
		let uCountry = user.country;
		uCountry = uCountry.toLowerCase();
		let uCurrency = user && user.country === "UK" ? "£" : "R";

		let uLimits = limits ? limits.limits[uCountry][type][slot] : null;

		if (uLimits) {
			return (
				<>
					<h5 className="m-0 fw-bold">
						{uCurrency}{" "}
						{Number(uLimits)
							.toFixed(2)
							.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
					</h5>
					{profile && profile.middleware.kyc_status === type && (
						<p className="m-0">
							{uCurrency}
							{Number(limits.amountsSent[amount])
								.toFixed(2)
								.replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
							Sent
						</p>
					)}
				</>
			);
		} else {
			return "--";
		}
	};

	let content;

	if (limits_loading || profile_loading) {
		content = <PageLoader />;
	} else if (limits && !limits_loading && profile) {
		content = (
			<div className="py-0 py-sm-3" style={{ minHeight: "70%" }}>
				<div className="container">
					<div className="row">
						<div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
							<h5 className="fw-bold py-1 py-lg-4 text-center">My Limits</h5>
						</div>
						<div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
							{limits_error && limits_error.map((err) => <Error key={err.error} error={err.error} />)}
							{profile && profile.middleware.kyc_status !== "unlimited" && (
								<p className="p-4 rounded" style={{ backgroundColor: "#FCE9E9" }}>
									We require some additional information from you. This information allows
									us to protect your account and comply with banking laws.
								</p>
							)}
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row mb-4 row-cols-sm-3 row-col-xs-2">
						<div
							className={
								user && user.country === "UK"
									? "col-lg-3 col-md-3 mb-4 d-none d-lg-block"
									: "col-lg-4 col-md-3 mb-4 d-none d-lg-block"
							}
						>
							<div
								className="borderRadiusTop-20px bg-white d-flex flex-column align-items-center justify-content-center"
								style={{ height: "60px" }}
							></div>

							<div
								className="borderRadiusTop-20px bg-white d-flex flex-column align-items-center justify-content-center"
								style={{ height: "60px" }}
							>
								<h6 className="fw-bold">Per 24 hours</h6>
							</div>

							<div
								className="borderRadiusTop-20px bg-white d-flex flex-column align-items-center justify-content-center"
								style={{ height: "60px" }}
							>
								<h6 className="fw-bold">Per Month</h6>
							</div>

							<div
								className="borderRadiusTop-20px bg-white d-flex flex-column align-items-center justify-content-center"
								style={{ height: "60px" }}
							>
								<h6 className="fw-bold">Per Quarter</h6>
							</div>

							<div
								className="borderRadiusTop-20px bg-white d-flex flex-column align-items-center justify-content-center"
								style={{ height: "60px" }}
							>
								<h6 className="fw-bold">Per Year</h6>
							</div>
						</div>

						<div className={user && user.country === "UK" ? "col-lg-3" : "col-lg-3 col-sm-6"}>
							<div
								className="border mb-4 mb-sm-0 borderRadiusTop-20px borderRadiusBottom-10px"
								style={{ boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.25)" }}
							>
								<div
									className={`borderRadiusTop-20px ${
										profile && profile.middleware.kyc_status === "lite"
											? "bgBlue"
											: "bgYellow"
									} d-flex flex-column align-items-center justify-content-center`}
									style={{ height: "60px" }}
								>
									<p className="m-0 fw-bold">Lite</p>
									{profile && profile.middleware.kyc_status === "lite" && (
										<p className="m-0">(Current)</p>
									)}
								</div>
								<div
									className="d-flex flex-column align-items-center justify-content-center"
									style={{ height: "60px" }}
								>
									{displayLimits("lite", "day", "day")}
									<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
										Per Day
									</span>
								</div>
								<div
									className="d-flex flex-column align-items-center justify-content-center"
									style={{ height: "60px" }}
								>
									{displayLimits("lite", "month", "month")}

									<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
										Per Month
									</span>
								</div>
								<div
									className="d-flex flex-column align-items-center justify-content-center"
									style={{ height: "60px" }}
								>
									{displayLimits("lite", "quarter", "quarter")}

									<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
										Per Quarter
									</span>
								</div>
								<div
									className="d-flex flex-column align-items-center justify-content-center"
									style={{ height: "60px" }}
								>
									{displayLimits("lite", "year", "year")}

									<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
										Per Year
									</span>
								</div>

								{(profile &&
									profile.middleware.kyc_status !== "limited" &&
									user &&
									profile.middleware.kyc_status !== "unlimited") ||
								profile.middleware.kyc_status === "limited" ||
								user ||
								profile.middleware.kyc_status === "unlimited" ? (
									<div style={{ height: "60px" }}></div>
								) : (
									<div></div>
								)}

								<div
									className="d-flex border-top flex-column align-items-center pt-3"
									style={{ height: "123px" }}
								>
									<h6 className="m-0 fw-bold">Information Needed</h6>
									<p className="px-0 text-center" style={{ paddingTop: 10 }}>
										<span>Full Name</span>
										<br />
										<span>Gender</span>
										<br />
										<span>Date of Birth</span>
									</p>
								</div>
							</div>
						</div>

						<div className={user && user.country === "UK" ? "col-lg-3" : "col-lg-3 col-sm-6"}>
							<div
								className="border mb-4 mb-sm-0 borderRadiusTop-20px borderRadiusBottom-10px"
								style={{ boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.25)" }}
							>
								<div
									className={`borderRadiusTop-20px ${
										profile && profile.middleware.kyc_status === "limited"
											? "bgBlue"
											: "bgYellow"
									} d-flex flex-column align-items-center justify-content-center`}
									style={{ height: "60px" }}
								>
									<p className="m-0 fw-bold">Limited</p>
									{profile && profile.middleware.kyc_status === "limited" && (
										<p className="m-0">(Current)</p>
									)}
								</div>
								<div
									className="d-flex flex-column align-items-center justify-content-center"
									style={{ height: "60px" }}
								>
									{displayLimits("limited", "day", "day")}

									<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
										Per Day
									</span>
								</div>
								<div
									className="d-flex flex-column align-items-center justify-content-center"
									style={{ height: "60px" }}
								>
									{displayLimits("limited", "month", "month")}

									<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
										Per Month
									</span>
								</div>
								<div
									className="d-flex flex-column align-items-center justify-content-center"
									style={{ height: "60px" }}
								>
									{displayLimits("limited", "quarter", "quarter")}

									<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
										Per Quarter
									</span>
								</div>
								<div
									className="d-flex flex-column align-items-center justify-content-center"
									style={{ height: "60px" }}
								>
									{displayLimits("limited", "year", "year")}

									<span className="d-sm-block d-lg-none  text-center border border-warning rounded-3 ig">
										Per Year
									</span>
								</div>
								{profile &&
									profile.middleware.kyc_status !== "limited" &&
									profile.middleware.kyc_status !== "unlimited" && (
										<div
											className="d-flex flex-column align-items-center justify-content-center"
											style={{ height: "60px" }}
										>
											<Link
												to="/upgrade-limited"
												className="btn bgBlue text-white borderRadius-10px"
											>
												Upgrade Now
											</Link>
										</div>
									)}

								{profile && profile.middleware.kyc_status === "limited" && (
									<div style={{ height: "60px" }}></div>
								)}
								<div
									className="d-flex border-top flex-column align-items-center pt-3"
									style={{ height: "123px" }}
								>
									<h6 className="m-0 fw-bold">Information Needed</h6>
									<p className="px-0 text-center" style={{ paddingTop: 10 }}>
										<span>Physical Address</span>
										<br />
										<span>Identity Document</span>
									</p>
								</div>
							</div>
						</div>
						{user && user.country === "UK" && (
							<div className="col-lg-3">
								<div
									className="border mb-4 mb-sm-0 borderRadiusTop-20px borderRadiusBottom-10px"
									style={{ boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.25)" }}
								>
									<div
										className={`borderRadiusTop-20px ${
											profile && profile.middleware.kyc_status === "unlimited"
												? "bgBlue"
												: "bgYellow"
										} d-flex flex-column align-items-center justify-content-center`}
										style={{ height: "60px" }}
									>
										<p className="m-0 fw-bold">Unlimited</p>
										{profile && profile.middleware.kyc_status === "unlimited" && (
											<p className="m-0">(Current)</p>
										)}
									</div>
									<div
										className="d-flex flex-column align-items-center justify-content-center"
										style={{ height: "60px" }}
									>
										{displayLimits("unlimited", "day", "today")}

										<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
											Per Day
										</span>
									</div>
									<div
										className="d-flex flex-column align-items-center justify-content-center"
										style={{ height: "60px" }}
									>
										{displayLimits("unlimited", "month", "month")}

										<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
											Per Month
										</span>
									</div>
									<div
										className="d-flex flex-column align-items-center justify-content-center"
										style={{ height: "60px" }}
									>
										{displayLimits("unlimited", "quarter", "quarter")}

										<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
											Per Quarter
										</span>
									</div>
									<div
										className="d-flex flex-column align-items-center justify-content-center"
										style={{ height: "60px" }}
									>
										{displayLimits("unlimited", "year", "year")}

										<span className="d-sm-block d-lg-none text-center border border-warning rounded-3 ig">
											Per Year
										</span>
									</div>
									{profile && profile.middleware.kyc_status !== "unlimited" ? (
										<div
											className="d-flex flex-column align-items-center justify-content-center"
											style={{ height: "60px" }}
										>
											<Link
												to="/upgrade-unlimited"
												className="btn bgBlue text-white borderRadius-10px"
											>
												Upgrade Now
											</Link>
										</div>
									) : profile.middleware.kyc_status !== "limited" ? (
										<div></div>
									) : (
										<div style={{ height: "60px" }}></div>
									)}
									<div
										className="d-flex border-top flex-column align-items-center pt-3"
										style={{ height: "123px" }}
									>
										<h6 className="m-0 fw-bold">Information Needed</h6>
										<p className="px-0 text-center" style={{ paddingTop: 10 }}>
											<span>
												Including <strong>Limited</strong> Requirements
											</span>
											<br />
											<span>Source of Funds</span>
										</p>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="vh-100">
			<SessionTimeout />
			<div style={{ minHeight: "16%" }}>
				{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
			</div>
			{content}
			<div style={{ minHeight: "14%" }}>
				<Footer user={user} uioptions={uioptions} />
			</div>
		</div>
	);
};

UpgradeKyc.propTypes = {
	getLimits: PropTypes.func.isRequired,
	getProfile: PropTypes.func.isRequired,
	clearGetLimitsError: PropTypes.func.isRequired,
	getLimitsReducer: PropTypes.object.isRequired,
	uioptions: PropTypes.object.isRequired,
	authorization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	getLimitsReducer: state.getLimitsReducer,
	uioptions: state.uioptions,
	authorization: state.authorization,
	myProfile: state.myProfile,
});

export default connect(mapStateToProps, actions)(UpgradeKyc);
