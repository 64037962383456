import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Password } from "components";
import { AuthLayout } from "layouts";
import * as actions from "store/actions";

const PasswordReset = (props) => {
	const { uioptions } = props;

	useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Password Reset";
		return () => {
			subscribe = false;
		};
	}, []);

	const initialValues = {
		password: "",
	};

	const passwordSchema = Yup.object().shape({
		password: Yup.string().required().label("Password"),
	});

	const handleReset = () => {};

	return (
		<AuthLayout uioptions={uioptions} heading="">
			{
				<Formik
					initialValues={initialValues}
					validationSchema={passwordSchema}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setSubmitting(true);
						handleReset(values);
						resetForm();
						setSubmitting(false);
					}}
				>
					{() => (
						<Form Form>
							<p className="fw-bold">Forgot Password?</p>
							<p>RESET PASSWORD</p>
							{/* <TextField floatingtext="Email" name="email" /> */}
							<Password floatingtext="Password*" name="password" type="password" />
							<div className="d-grid gap-2 mt-3 mb-3">
								<button className="btn btn-primary bgBlue" type="submit">
									<i className="pi pi-lock"></i> Reset password
								</button>
								<Link
									to="/"
									style={{
										textDecoration: "none",
										color: "#3B366F",
									}}
								>
									<strong>Back to Login</strong>
								</Link>
							</div>
						</Form>
					)}
				</Formik>
			}
		</AuthLayout>
	);
};

PasswordReset.propTypes = {
	authorization: PropTypes.object.isRequired,
	login: PropTypes.func.isRequired,
	uioptions: PropTypes.object.isRequired,
	getUIoptions: PropTypes.func.isRequired,
	clearLoginError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	authorization: state.authorization,
	uioptions: state.uioptions,
});

export default connect(mapStateToProps, actions)(PasswordReset);
