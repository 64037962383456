import React from 'react';
import { ErrorMessage, useField } from 'formik';

const CheckBox = ({ name, label, value, ...props }) => {
    const [field, meta] = useField(name);
    return (
        <>
            <div className="form-check">
                <input className={`form-check-input ${meta.touched && meta.error && 'is-invalid'}`}
                    type="checkbox"
                    id={name}
                    {...field}
                    {...props}
                />
                <label htmlFor={name} className="form-check-label fw-bold">
                    {label || value}
                </label>
            </div>
            <ErrorMessage component="div" name={field.name} className="ms-2 mt-0 text-danger" />
        </>
    )
}

export default CheckBox;
