import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

const ProtectedRoute = ({ component: Component, ...props }) => {
	// const { getProfile } = props;
	const { authenticated, user } = props.authorization;
	const { profile } = props.myProfile;

	const path = props.path;	

	let history = useHistory();

	return (
		<Route
			{...props}
			render={(props) => {
				let status = profile ? profile.remitone.status : user ? user.remitone_status : null;
				if(status){
					if (
						status !== "valid" &&
						path !== "/profile" &&
						path !== "/transactions" &&
						path !== "/change-password" &&
						path !== "/profile/update" &&
						path !== "/beneficiaries" &&
						path !== "/beneficiaries/new" &&
						path !== "/beneficiaries/:slug"
					) {
						history.push("/account-locked");
						// return (
						// 	<Redirect to={{ pathname: "/account-locked", state: { from: props.location } }} />
						// );
					}
					if (authenticated) return <Component {...props} />;
				}
				return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
			}}
		/>
	);
};

ProtectedRoute.propTypes = {
	myProfile: PropTypes.object.isRequired,
	authorization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	myProfile: state.myProfile,
	authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(ProtectedRoute);
