/* eslint-disable import/no-anonymous-default-export */
import {CLEAR_VERIFY_OTP, CLEAR_VERIFY_OTP_ERROR, VERIFY_OTP_LOADING, VERIFY_OTP_ERROR, VERIFY_OTP} from '../actions/types';

const initialState = {
    verify_otp_data: null,
    verify_otp_loading: false,
    verify_otp_error: null
}

export default function (state = initialState, action){
    switch (action.type) {
        case CLEAR_VERIFY_OTP:
            return {
                ...state,
                verify_otp_data: null,
                verify_otp_loading: false,
                verify_otp_error: null
            }
        case CLEAR_VERIFY_OTP_ERROR:
            return {
                ...state,
                verify_otp_loading: false,
                verify_otp_error: null
            }
        case VERIFY_OTP_LOADING:
            return {
                ...state,
                verify_otp_loading: true,
                verify_otp_error: null
            }
        case VERIFY_OTP_ERROR:
            return {
                ...state,
                verify_otp_loading: false,
                verify_otp_error: action.payload
            }
        case VERIFY_OTP:
            return {
                ...state,
                verify_otp_data: action.payload,
                verify_otp_loading: false,
                verify_otp_error: null
            }
        default:
            return state
    }
}