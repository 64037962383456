import React from "react";
import { Error, RegularButton } from "components";
import { useForm } from "react-hook-form";

const Confirmation = ({
	amntToSend,
	nb_error,
	new_transaction_error,
	initiate_payment_error,
	confirm_transaction_error,
	myBeneficiary,
	myProfile,
	transaction_charges_data,
	user,
	ui,
	setChecked,
	calculateTotalCharges,
	setDeclarationError,
	declarationError,
	clearTransactionPayloadError,
	clearNewBeneficiaryError,
	clearInitiatePaymentError,
	clearConfirmTransactionError,
	user_error,
	determineRate,
	clearAuthorizationError,
	clearRemitOneError,
	remit_one_error,
	performBasicRegistration,
	handleStep,
}) => {
	const handleTermsAndConditions = () => {
		window.open(ui.result.links.terms, "_blank");
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();

	const handlePrivacyPolicy = () => {
		window.open(ui.result.links.privacy, "_blank");
	};

	//CLEAR DECLARATION ERROR
	React.useEffect(() => {
		const time_id = setInterval(() => {
			declarationError.length > 0 && setDeclarationError("");
		}, 20000);

		return () => {
			clearTimeout(time_id);
		};
	}, [declarationError, setDeclarationError]);

	//CLEAR NEW TRANSACTION ERROR
	React.useEffect(() => {
		const time_id = setInterval(() => {
			new_transaction_error && clearTransactionPayloadError();
		}, 20000);

		return () => {
			clearTimeout(time_id);
		};
	}, [new_transaction_error, clearTransactionPayloadError]);

	//CLEAR NEW BENEFICIARY ERROR
	React.useEffect(() => {
		const time_id = setInterval(() => {
			nb_error && clearNewBeneficiaryError();
		}, 20000);

		return () => {
			clearTimeout(time_id);
		};
	}, [nb_error, clearNewBeneficiaryError]);

	//CLEAR INITIATE PAYMENT ERROR
	React.useEffect(() => {
		const time_id = setInterval(() => {
			initiate_payment_error && clearInitiatePaymentError();
		}, 20000);

		return () => {
			clearTimeout(time_id);
		};
	}, [initiate_payment_error, clearInitiatePaymentError]);

	//CLEAR CONFIRM TRANSACTION ERROR
	React.useEffect(() => {
		const time_id = setInterval(() => {
			confirm_transaction_error && clearConfirmTransactionError();
		}, 15000);

		return () => {
			clearTimeout(time_id);
		};
	}, [confirm_transaction_error, clearConfirmTransactionError]);

	//CLEAR FULL REGISTRATION ERROR
	React.useEffect(() => {
		const time_id = setInterval(() => {
			user_error && clearAuthorizationError();
		}, 15000);

		return () => {
			clearTimeout(time_id);
		};
	}, [user_error, clearAuthorizationError]);

	//CLEAR REMIT ONE ERROR
	React.useEffect(() => {
		const time_id = setInterval(() => {
			remit_one_error && clearRemitOneError();
		}, 15000);

		return () => {
			clearTimeout(time_id);
		};
	}, [remit_one_error, clearRemitOneError]);

	const convertPaymentMethod = (id) => {
		let corridor = user.country;
		let paymentMethod =
			ui && ui.result.payment_methods[corridor].find((el) => Number(el.id) === Number(id));
		return paymentMethod.name;
	};

	return (
		<form onSubmit={handleSubmit(performBasicRegistration)}>
			{nb_error && nb_error.map((err) => <Error key={err.error} error={err.error} />)}
			{user_error && user_error.map((err) => <Error key={err.error} error={err.error} />)}
			{remit_one_error &&
				remit_one_error.map((err) => <Error key={err.error} error={err.error} />)}
			{new_transaction_error &&
				new_transaction_error.map((err) => <Error key={err.error} error={err.error} />)}
			{initiate_payment_error &&
				initiate_payment_error.map((err) => <Error key={err.error} error={err.error} />)}
			{confirm_transaction_error &&
				confirm_transaction_error.map((err) => <Error key={err.error} error={err.error} />)}

			<h5 className="fw-bold">Confirmation</h5>
			<div className="row">
				<div className="col-md-12 text-start fw-bold my-3">Amount to Send</div>
				<div className="col-6 text-start">Sending</div>
				<div className="col-6 text-start">
					{amntToSend.source_currency +
						" " +
						Number(amntToSend.amount_to_send)
							.toFixed(2)
							.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				</div>
				<div className="col-6 text-start">Receiving</div>
				<div className="col-6 text-start">
					{amntToSend.destination_currency +
						" " +
						Number(amntToSend.amount_to_receive)
							.toFixed(2)
							.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				</div>
				<div className="col-6 text-start">Transfer Type</div>
				<div className="col-6 text-start">{amntToSend.trans_type}</div>
				<div className="col-6 text-start">Payment Method</div>
				<div className="col-6 text-start">
					{convertPaymentMethod(amntToSend.payment_method)}
				</div>
				<div className="col-6 text-start">Purpose</div>
				<div className="col-6 text-start">{amntToSend.purpose}</div>
				<div className="col-6 text-start">Rate</div>
				<div className="col-6 text-start">
					{user.country === "UK" ? "£" : amntToSend.destination_currency === "ZAR" ? "R" : "$"}
					1:{determineRate()}
				</div>
				<div className="col-6 text-start">Total Charges</div>
				<div className="col-6 text-start">{calculateTotalCharges()}</div>

				{amntToSend.ref_code && (
					<>
						<div className="col-6 text-start">Referral Code</div>
						<div className="col-6 text-start">{amntToSend.ref_code}</div>
					</>
				)}
				<div className="col-6 text-start">Total to Pay</div>
				<div className="col-6 text-start">
					{user.country === "UK" ? "£" : "R"}{" "}
					{(
						Number(transaction_charges_data.result.source_amount) +
						Number(transaction_charges_data.result.total_charges) +
						Number(transaction_charges_data.result.tax)
					)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				</div>
				<div className="col-md-12 text-start fw-bold my-3">Beneficiary Details</div>
				<div className="col-6 text-start">First Name</div>
				<div className="col-6 text-start">{myBeneficiary.first_name}</div>
				{myBeneficiary.middle_name.length > 0 && (
					<>
						<div className="col-6 text-start">Middle Name</div>
						<div className="col-6 text-start">{myBeneficiary.middle_name}</div>
					</>
				)}
				<div className="col-6 text-start">Last Name</div>
				<div className="col-6 text-start">{myBeneficiary.last_name}</div>
				<div className="col-6 text-start">Mobile Number</div>
				<div className="col-6 text-start">
					{myBeneficiary.mobile.startsWith("+")
						? myBeneficiary.mobile
						: "+" + myBeneficiary.mobile}
				</div>
				{!user.onboarded && (
					<>
						<div className="col-md-12 text-start fw-bold my-3">Sender Details</div>
						<div className="col-6 text-start">First Name</div>
						<div className="col-6 text-start">{myProfile.first_name}</div>
						{myProfile.middle_name && (
							<>
								<div className="col-6 text-start">Middle Name(s)</div>
								<div className="col-6 text-start">{myProfile.middle_name}</div>
							</>
						)}
						<div className="col-6 text-start">Last Name</div>
						<div className="col-6 text-start">{myProfile.last_name}</div>
						<div className="col-6 text-start">Mobile Number</div>
						<div className="col-6 text-start">
							{myProfile.mobile.startsWith("+") ? myProfile.mobile : "+" + myProfile.mobile}
						</div>
						<div className="col-6 text-start">Date of Birth</div>
						<div className="col-6 text-start">{myProfile.dob}</div>
						<div className="col-6 text-start">Gender</div>
						<div className="col-6 text-start">{myProfile.gender}</div>
						{myProfile.address_line_1 && (
							<>
								<div className="col-6 text-start">Postal Code</div>
								<div className="col-6 text-start">{myProfile.postal_code ?? "--"}</div>
								<div className="col-6 text-start">Address</div>
								<div className="col-6 text-start">{`${myProfile.address_line_1}, ${myProfile.address_line_2}, ${myProfile.city}, ${myProfile.state_province}`}</div>
							</>
						)}
						{user && user.country === "SA" && (
							<>
								<div className="col-6 text-start">ID Number</div>
								<div className="col-6 text-start">{myProfile.id1_details ?? "--"}</div>

								<div className="col-6 text-start">ID Type</div>
								<div className="col-6 text-start">{myProfile.id1_type_label ?? "--"}</div>

								<div className="col-6 text-start">ID Issue Place</div>
								<div className="col-6 text-start">{myProfile.id1_issue_place ?? "--"}</div>

								<div className="col-6 text-start">ID Issue Country</div>
								<div className="col-6 text-start">
									{myProfile.id1_issue_country ?? "--"}
								</div>

								<div className="col-6 text-start">ID Expiry Date</div>
								<div className="col-6 text-start">{myProfile.id1_expiry ?? "--"}</div>
								<div className="col-6 text-start">Default Purpose</div>
								<div className="col-6 text-start">
									{myProfile.default_transfer_purpose ?? "--"}
								</div>
							</>
						)}
					</>
				)}
			</div>

			<div className="bgYellow rounded p-4 mt-3" style={{ background: "#B5D7F7" }}>
				<p>
					{user &&
						user.country === "UK" &&
						ui &&
						ui.result.disclaimers.transaction_confirmation.uk}
					{user &&
						user.country === "SA" &&
						ui &&
						ui.result.disclaimers.transaction_confirmation.sa}
				</p>
				<p className="mb-0">{ui && ui.result.disclaimers.transaction_confirmation.all}</p>
			</div>
			{errors.declaration && errors.declaration.type === "required" && (
				<div className="alert alert-warning mt-4" role="alert">
					You cannot proceed unless you have agreed to the terms and conditions of our service
				</div>
			)}
			<div className="mt-3">
				<div className="form-check">
					<input
						type="checkbox"
						{...register("declaration", { required: true })}
						name="declaration"
						id="declare"
					/>{" "}
					<label htmlFor="declare" className="form-check-label">
						Declaration,{" "}
						<span
							style={{ color: "blue", cursor: "pointer" }}
							onClick={handleTermsAndConditions}
						>
							Terms and Conditions
						</span>{" "}
						and{" "}
						<span style={{ color: "blue", cursor: "pointer" }} onClick={handlePrivacyPolicy}>
							Privacy Policy
						</span>{" "}
						of service.
					</label>
				</div>
			</div>
			<div className="mt-4">
				<RegularButton
					onClick={() => handleStep("confirmStep", "back")}
					value="Back"
					bg="bgYellow"
					type="button"
					floatright={false}
				/>
				<RegularButton
					// onClick={performBasicRegistration}
					value="Make Payment"
					type="submit"
					floatright={true}
				/>
			</div>
		</form>
	);
};

export default Confirmation;
