export const AUTHORIZATION_LOADING = "AUTHORIZATION_LOADING";
export const AUTHORIZATION = "AUTHORIZATION";
export const AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR";
export const CLEAR_AUTHORIZATION_ERROR = "CLEAR_AUTHORIZATION_ERROR";
export const CLEAR_AUTHORIZATION = "CLEAR_AUTHORIZATION";

export const FETCH_UIOPTIONS_LOADING = "FETCH_UIOPTIONS_LOADING";
export const FETCH_UIOPTIONS = "FETCH_UIOPTIONS";
export const FETCH_UIOPTIONS_ERROR = "FETCH_UIOPTIONS_ERROR";
export const CLEAR_FETCH_UIOPTIONS = "CLEAR_FETCH_UIOPTIONS";
export const CLEAR_FETCH_UIOPTIONS_ERROR = "CLEAR_FETCH_UIOPTIONS_ERROR";

export const REMIT_ONE = "REMIT_ONE";
export const REMIT_ONE_ERROR = "REMIT_ONE_ERROR";
export const CLEAR_REMIT_ONE_ERROR = "CLEAR_REMIT_ONE_ERROR";
export const CLEAR_REMIT_ONE = "CLEAR_REMIT_ONE";
export const REMIT_ONE_LOADING = "REMIT_ONE_LOADING";

export const SET_TRANSACTION_CHARGES_LOADING = "SET_TRANSACTION_CHARGES_LOADING";
export const TRANSACTION_CHARGES = "TRANSACTION_CHARGES";
export const SET_TRANSACTION_CHARGES_ERROR = "SET_TRANSACTION_CHARGES_ERROR";
export const CLEAR_TRANSACTION_CHARGES_ERROR = "CLEAR_TRANSACTION_CHARGES_ERROR";
export const CLEAR_TRANSACTION_CHARGES = "CLEAR_TRANSACTION_CHARGES";

export const SET_CHANGE_PASSWORD_LOADING = "SET_CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CLEAR_CHANGE_PASSWORD = "CLEAR_CHANGE_PASSWORD";
export const CLEAR_CHANGE_PASSWORD_ERROR = "CLEAR_CHANGE_PASSWORD_ERROR";
export const SET_CHANGE_PASSWORD_ERROR = "SET_CHANGE_PASSWORD_ERROR";

export const SET_RESET_PASSWORD_LOADING = "SET_RESET_PASSWORD_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CLEAR_RESET_PASSWORD = "CLEAR_RESET_PASSWORD";
export const CLEAR_RESET_PASSWORD_ERROR = "CLEAR_RESET_PASSWORD_ERROR";
export const SET_RESET_PASSWORD_ERROR = "SET_RESET_PASSWORD_ERROR";

export const SET_NEW_BENEFICIARY_LOADING = "SET_NEW_BENEFICIARY_LOADING";
export const NEW_BENEFICIARY = "NEW_BENEFICIARY";
export const CLEAR_NEW_BENEFICIARY = "CLEAR_NEW_BENEFICIARY";
export const CLEAR_NEW_BENEFICIARY_ERROR = "CLEAR_NEW_BENEFICIARY_ERROR";
export const SET_NEW_BENEFICIARY_ERROR = "SET_NEW_BENEFICIARY_ERROR";

export const SET_UPDATE_BENEFICIARY_LOADING = "SET_UPDATE_BENEFICIARY_LOADING";
export const UPDATE_BENEFICIARY = "UPDATE_BENEFICIARY";
export const SET_UPDATE_BENEFICIARY_ERROR = "SET_UPDATE_BENEFICIARY_ERROR";
export const CLEAR_UPDATE_BENEFICIARY_ERROR = "CLEAR_UPDATE_BENEFICIARY_ERROR";
export const CLEAR_UPDATE_BENEFICIARY = "CLEAR_UPDATE_BENEFICIARY";

export const SET_BENEFICIARIES_LOADING = "SET_BENEFICIARIES_LOADING";
export const ALL_BENEFICIARIES = "ALL_BENEFICIARIES";
export const ALL_BENEFICIARIES_ERROR = "ALL_BENEFICIARIES_ERROR";
export const CLEAR_ALL_BENEFICIARIES = "CLEAR_ALL_BENEFICIARIES";
export const CLEAR_ALL_BENEFICIARIES_ERROR = "CLEAR_ALL_BENEFICIARIES_ERROR";

export const SET_SINGLE_BENEFICIARY_LOADING = "SET_SINGLE_BENEFICIARY_LOADING";
export const SINGLE_BENEFICIARY = "SINGLE_BENEFICIARY";
export const SINGLE_BENEFICIARY_ERROR = "SINGLE_BENEFICIARY_ERROR";
export const CLEAR_SINGLE_BENEFICIARY = "CLEAR_SINGLE_BENEFICIARY";
export const CLEAR_SINGLE_BENEFICIARY_ERROR = "CLEAR_SINGLE_BENEFICIARY_ERROR";

export const SET_NEW_TRANSACTION_LOADING = "SET_NEW_TRANSACTION_LOADING";
export const SET_NEW_TRANSACTION_ERROR = "SET_NEW_TRANSACTION_ERROR";
export const NEW_TRANSACTION = "NEW_TRANSACTION";
export const CLEAR_NEW_TRANSACTION = "CLEAR_NEW_TRANSACTION";
export const CLEAR_NEW_TRANSACTION_ERROR = "CLEAR_NEW_TRANSACTION_ERROR";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const CLEAR_GET_TRANSACTIONS = "CLEAR_GET_TRANSACTIONS";
export const CLEAR_GET_TRANSACTIONS_ERROR = "CLEAR_GET_TRANSACTIONS_ERROR";
export const GET_TRANSACTIONS_ERROR = "GET_TRANSACTIONS_ERROR";
export const GET_TRANSACTIONS_LOADING = "GET_TRANSACTIONS_LOADING";

export const GET_LIMITS = "GET_LIMITS";
export const CLEAR_GET_LIMITS = "CLEAR_GET_LIMITS";
export const CLEAR_GET_LIMITS_ERROR = "CLEAR_GET_LIMITS_ERROR";
export const GET_LIMITS_ERROR = "GET_LIMITS_ERROR";
export const GET_LIMITS_LOADING = "GET_LIMITS_LOADING";

export const GET_TRANSACTION = "GET_TRANSACTION";
export const CLEAR_GET_TRANSACTION = "CLEAR_GET_TRANSACTION";
export const CLEAR_GET_TRANSACTION_ERROR = "CLEAR_GET_TRANSACTION_ERROR";
export const GET_TRANSACTION_ERROR = "GET_TRANSACTION_ERROR";
export const GET_TRANSACTION_LOADING = "GET_TRANSACTION_LOADING";

export const SET_UPDATE_PROFILE_LOADING = "SET_UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SET_UPDATE_PROFILE_ERROR = "SET_UPDATE_PROFILE_ERROR";
export const CLEAR_UPDATE_PROFILE_ERROR = "CLEAR_UPDATE_PROFILE_ERROR";
export const CLEAR_UPDATE_PROFILE = "CLEAR_UPDATE_PROFILE";

export const CONFIRM_TRANSACTION = "CONFIRM_TRANSACTION";
export const CONFIRM_TRANSACTION_LOADING = "CONFIRM_TRANSACTION_LOADING";
export const CONFIRM_TRANSACTION_ERROR = "CONFIRM_TRANSACTION_ERROR";
export const CLEAR_CONFIRM_TRANSACTION_ERROR = "CLEAR_CONFIRM_TRANSACTION_ERROR";
export const CLEAR_CONFIRM_TRANSACTION = "CLEAR_CONFIRM_TRANSACTION";

export const STORE_ONBOARDING_PAYLOAD = "STORE_ONBOARDING_PAYLOAD";
export const CLEAR_ONBOARDING_PAYLOAD = "CLEAR_ONBOARDING_PAYLOAD";

export const CLEAR_TRANSACTION_LOADING = "CLEAR_TRANSACTION_LOADING";
export const CLEAR_TRANSACTION_PAYLOAD = "CLEAR_TRANSACTION_PAYLOAD";
export const SET_CLEAR_TRANSACTION_ERROR = "SET_CLEAR_TRANSACTION_ERROR";
export const CLEAR_TRANSACTION_PAYLOAD_ERROR = "CLEAR_TRANSACTION_PAYLOAD_ERROR";
export const SET_CLEAR_TRANSACTION_STATE = "SET_CLEAR_TRANSACTION_STATE";

export const CLEAR_UPGRADE_PROFILE = "CLEAR_UPGRADE_PROFILE";
export const CLEAR_UPGRADE_PROFILE_ERROR = "CLEAR_UPGRADE_PROFILE_ERROR";
export const SET_UPGRADE_PROFILE_LOADING = "SET_UPGRADE_PROFILE_LOADING";
export const UPGRADE_PROFILE_ERROR = "UPGRADE_PROFILE_ERROR";
export const UPGRADE_PROFILE = "UPGRADE_PROFILE";

export const CLEAR_INITIATE_PAYMENT = "CLEAR_INITIATE_PAYMENT";
export const CLEAR_INITIATE_PAYMENT_ERROR = "CLEAR_INITIATE_PAYMENT_ERROR";
export const INITIATE_PAYMENT_LOADING = "INITIATE_PAYMENT_LOADING";
export const INITIATE_PAYMENT = "INITIATE_PAYMENT";
export const INITIATE_PAYMENT_ERROR = "INITIATE_PAYMENT_ERROR";

export const SET_POLL_PAYMENT_LOADING = "SET_POLL_PAYMENT_LOADING";
export const CLEAR_POLL_PAYMENT_ERROR = "CLEAR_POLL_PAYMENT_ERROR";
export const CLEAR_POLL_PAYMENT = "CLEAR_POLL_PAYMENT";
export const POLL_PAYMENT_ERROR = "POLL_PAYMENT_ERROR";
export const POLL_PAYMENT = "POLL_PAYMENT";

export const PROFILE = "PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const CLEAR_PROFILE_ERROR = "CLEAR_PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

export const CLEAR_SEARCH_REMITTER_ERROR = "CLEAR_SEARCH_REMITTER_ERROR";
export const SEARCH_REMITTER_LOADING = "SEARCH_REMITTER_LOADING";
export const CLEAR_SEARCH_REMITTER = "CLEAR_SEARCH_REMITTER";
export const SEARCH_REMITTER_ERROR = "SEARCH_REMITTER_ERROR";
export const SEARCH_REMITTER = "SEARCH_REMITTER";

export const CLEAR_SEND_OTP = "CLEAR_SEND_OTP";
export const CLEAR_SEND_OTP_ERROR = "CLEAR_SEND_OTP_ERROR";
export const SEND_OTP_LOADING = "SEND_OTP_LOADING";
export const SEND_OTP_ERROR = "SEND_OTP_ERROR";
export const SEND_OTP = "SEND_OTP";

export const CLEAR_VERIFY_OTP = "CLEAR_VERIFY_OTP";
export const CLEAR_VERIFY_OTP_ERROR = "CLEAR_VERIFY_OTP_ERROR";
export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const VERIFY_OTP = "VERIFY_OTP";

export const CLEAR_ONBOARD_REMITTER = "CLEAR_ONBOARD_REMITTER";
export const CLEAR_ONBOARD_REMITTER_ERROR = "CLEAR_ONBOARD_REMITTER_ERROR";
export const ONBOARD_REMITTER_LOADING = "ONBOARD_REMITTER_LOADING";
export const ONBOARD_REMITTER_ERROR = "ONBOARD_REMITTER_ERROR";
export const ONBOARD_REMITTER = "ONBOARD_REMITTER";

export const CLEAR_CHECK_AWAIT_PAYMENT = "CLEAR_CHECK_AWAIT_PAYMENT";
export const CLEAR_CHECK_AWAIT_PAYMENT_ERROR = "CLEAR_CHECK_AWAIT_PAYMENT_ERROR";
export const CHECK_AWAIT_PAYMENT_LOADING = "CHECK_AWAIT_PAYMENT_LOADING";
export const CHECK_AWAIT_PAYMENT_ERROR = "CHECK_AWAIT_PAYMENT_ERROR";
export const CHECK_AWAIT_PAYMENT = "CHECK_AWAIT_PAYMENT";
