// import { history } from "react-router-dom";

const links = {
	fb: "https://www.facebook.com/AccessForexIntl",
	tw: "https://twitter.com/accessforex_com",
	yt: "https://www.youtube.com/channel/UCR7w7__gGmRDHcoj1K3XUoQ/",
	ins: "https://www.instagram.com/accessforex_com/?hl=en",
	lin: "https://www.linkedin.com/company/accessforex-com/",
};

const socialMediaItems = [
	{
		label: "Facebook",
		icon: "pi pi-facebook",
		command: () => {
			window.open(links.fb, "_blank");
		},
	},
	{
		label: "Twitter",
		icon: "pi pi-twitter",
		command: () => {
			window.open(links.tw, "_blank");
		},
	},
	{
		label: "Youtube",
		icon: "pi pi-youtube",
		command: () => {
			window.open(links.yt, "_blank");
		},
	},
	{
		label: "Instagram",
		icon: "pi pi-heart",
		command: () => {
			window.open(links.ins, "_blank");
		},
	},
	{
		label: "LinkedIn",
		icon: "pi pi-heart",
		command: () => {
			window.open(links.lin, "_blank");
		},
	},
];

const webLinks = [
	{
		label: "About Us",
		command: () => {
			window.open("https://accessforex.com/about-us", "_blank");
		},
	},
	{
		label: "Collection Points",
		command: () => {
			window.open("https://accessforex.com/collection-points/", "_blank");
		},
	},
	{
		label: "How It Works",
		command: () => {
			window.open("https://accessforex.com/how-to-work/", "_blank");
		},
	},
	{
		label: "Contact Us",
		command: () => {
			window.open("https://accessforex.com/contact-us/", "_blank");
		},
	},
	{
		label: "support@accessforex.com",
		icon: "pi pi-link",
		command: () => {
			window.open("mailto:support@accessforex.com", "_blank");
		},
	},
	{
		label: "Help & Support",
		icon: "pi pi-link",
		command: () => {
			window.open("https://accessforex.com/help-and-support/", "_blank");
		},
	},
	{
		label: "Blog & News",
		icon: "pi pi-link",
		command: () => {
			window.open("https://accessforex.com/blog-and-news/", "_blank");
		},
	},
];

const allLinks = webLinks.concat(socialMediaItems);

export { links, socialMediaItems, allLinks, webLinks };
