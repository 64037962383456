import React from "react";
import { FiCheck } from "react-icons/fi";

const Step = ({ number, label, passed, active, children, ...props }) => {
	if (props.first) {
		return <div className="p-2 bgYellow" style={{ transform: "translate(-25px, 10px)" }}></div>;
	}

	if (props.last) {
		return <div className="p-2 bgYellow " style={{ transform: "translate(-25px, -8px)" }}></div>;
	}

	return (
		<div
			className={`py-2 ${passed || active ? "fw-bold" : "fw-normal"}`}
			// style={{ borderLeft: "1px solid #3B366F" }}
			style={{ borderLeft: "1px solid #3B366F" }}
		>
			<div
				className="p-0 m-0"
				style={{
					transform: props.first
						? "translate(-20px, 25px)"
						: props.last
						? "translate(-20px, -16px)"
						: "translate(-20px, 0px)",
					cursor: "pointer",
				}}
				{...props}
			>
				<div
					className={`text-center ${active ? "bgBlue text-white" : "bg-light"}`}
					style={{
						border: "1px solid #3B366F",
						borderRadius: "50px",
						paddingTop: "10px",
						paddingBottom: "10px",
						width: "40px",
						height: "40px",
						display: "inline-block",
					}}
				>
					{passed ? <FiCheck /> : number}
				</div>{" "}
				{label}
				{passed && (
					<div className="fw-normal pe-4 mt-2" style={{ transform: "translate(50px)" }}>
						{children}
					</div>
				)}
			</div>
		</div>
	);
};

export default Step;
