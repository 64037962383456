import React from 'react';
import { RegularButton } from 'components';


const TransactionStatus = ({ ...props }) => {
    return (
        <div className="mt-5">
            <div className="row mt-5">

                <div className="col-6 me-0 mt-5">
                    <div className={`${props.error ? 'bgGray' : 'bgPurple'} d-flex justify-content-center align-items-center`}
                        style={{
                            width: '200px',
                            height: '200px',
                            borderRadius: '50%'
                        }}
                    >

                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="#6B7280" stroke-width="0.3" stroke-linecap="round" stroke-linejoin="round" className="feather feather-smile"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                    </div>
                </div>

                <div className="col-6 mt-5">
                    <div className="mt-3"
                        style={{
                            position: 'relative',
                            right: '180px',
                            // top: '260px',
                            width: '300px'
                        }}
                    >
                        {props.error ?
                            (
                                <>
                                    <h4 className="fw-bold textMaroon">Oh Snap!</h4>
                                    <p>
                                        We have failed to process the transaction. This hardly ever happens, but not to worry we can fix it.
                                    </p>
                                    <RegularButton bg="bgMaroon" color="text-white" value="Try Again!" type="button" />
                                </>
                            ) :
                            (
                                <>
                                    <h4 className="fw-bold">Oh Yeah!</h4>
                                    <p>
                                        Welcome on board, <strong>John Doe</strong>. You have successfully completed your first transaction on Access Forex.
                                    </p>
                                    <RegularButton value="Take a Tour!" type="button" />
                                </>
                            )
                        }

                        {/* <RegularButton value="Take a tour!" type="button" /> */}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TransactionStatus
