/* eslint-disable import/no-anonymous-default-export */
import {CLEAR_SEARCH_REMITTER_ERROR, SEARCH_REMITTER_LOADING, CLEAR_SEARCH_REMITTER, SEARCH_REMITTER_ERROR, SEARCH_REMITTER} from '../actions/types';

const initialState = {
    search_remitter_data: null,
    search_remitter_loading: false,
    search_remitter_error: null
}

export default function (state = initialState, action){
    switch (action.type) {
        case CLEAR_SEARCH_REMITTER_ERROR:
           return {
               ...state,
               search_remitter_loading: false,
               search_remitter_error: null
           }
        case SEARCH_REMITTER_LOADING:
            return {
                ...state,
                search_remitter_loading: true,
                search_remitter_error: null
            }
        case CLEAR_SEARCH_REMITTER:
            return {
                ...state,
                search_remitter_data: null,
                search_remitter_loading: false,
                search_remitter_error: null
            }
        case SEARCH_REMITTER_ERROR:
            return {
                ...state,
                search_remitter_loading: false,
                search_remitter_error: action.payload
            }
        case SEARCH_REMITTER:
            return {
                ...state,
                search_remitter_data: action.payload,
                search_remitter_loading: false,
                search_remitter_error: null
            }
        default:
            return state
    }
}
