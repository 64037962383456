/* eslint-disable import/no-anonymous-default-export */
import {CONFIRM_TRANSACTION, CONFIRM_TRANSACTION_LOADING, CONFIRM_TRANSACTION_ERROR, CLEAR_CONFIRM_TRANSACTION_ERROR, CLEAR_CONFIRM_TRANSACTION} from '../actions/types';

const initialState = { confirm_transaction: null, confirm_transaction_loading: false, confirm_transaction_error: null };

export default function (state = initialState, action){
    switch (action.type) {
        case CONFIRM_TRANSACTION:
            return {
                ...state, confirm_transaction: action.payload, confirm_transaction_loading: false, confirm_transaction_error: null
            }
        case CONFIRM_TRANSACTION_LOADING:
            return {
                ...state, confirm_transaction_loading: true, confirm_transaction_error: null
            }
        case CONFIRM_TRANSACTION_ERROR:
            return {
                ...state, confirm_transaction_loading: true, confirm_transaction_error: action.payload
            }
        case CLEAR_CONFIRM_TRANSACTION_ERROR:
            return {
                ...state, confirm_transaction_loading: false, confirm_transaction_error: null 
            }
        case CLEAR_CONFIRM_TRANSACTION:
            return {
                ...state, confirm_transaction: null, confirm_transaction_loading: false, confirm_transaction_error: null 
            }
        default: return state 
    }
}

