/* eslint-disable import/no-anonymous-default-export */
import { SET_BENEFICIARIES_LOADING, ALL_BENEFICIARIES, ALL_BENEFICIARIES_ERROR, CLEAR_ALL_BENEFICIARIES, CLEAR_ALL_BENEFICIARIES_ERROR } from '../actions/types';

const initialState = { all_beneficiaries: null, all_beneficiaries_loading: false, all_beneficiaries_error: null }

export default function (state = initialState, action){
    switch(action.type){
        case SET_BENEFICIARIES_LOADING:
            return {
                ...state, all_beneficiaries_loading: true, all_beneficiaries_error: null
            }
        case ALL_BENEFICIARIES:
            return {
                ...state, all_beneficiaries: action.payload, all_beneficiaries_loading: false, all_beneficiaries_error: null
            }
        case ALL_BENEFICIARIES_ERROR:
            return {
                ...state, all_beneficiaries_error: action.payload, all_beneficiaries_loading: false
            }
        case CLEAR_ALL_BENEFICIARIES:
            return {
                ...state, all_beneficiaries: null, all_beneficiaries_loading: false, all_beneficiaries_error: null
            }
        case CLEAR_ALL_BENEFICIARIES_ERROR:
            return {
                ...state, all_beneficiaries_error: null, all_beneficiaries_loading: false
            }
        default: return state
    }
}