/* eslint-disable import/no-anonymous-default-export */
import {AUTHORIZATION_LOADING, AUTHORIZATION, AUTHORIZATION_ERROR, CLEAR_AUTHORIZATION_ERROR, CLEAR_AUTHORIZATION} from '../actions/types';

const initialState = { authenticated: false, user: null, user_loading: false, user_error: null }

export default function (state = initialState, action){
    switch (action.type) {
        case AUTHORIZATION_LOADING:
           return {
               ...state,
               user_error: null, 
               user_loading: true
           }
        case AUTHORIZATION:
            return {
                ...state,
                authenticated: true,
                user_error: null, 
                user_loading: false, 
                user: action.payload
            }
        case AUTHORIZATION_ERROR:
            return {
                ...state,
                user_error: action.payload, 
                user_loading: false, 
            }
        case CLEAR_AUTHORIZATION_ERROR:
            return {
                ...state,
                user_error: action.payload, 
                user_loading: false
            }
        case CLEAR_AUTHORIZATION:
            return {
                ...state,
                authenticated: false,
                user_error: null, 
                user_loading: false, 
                user: null
            }
        default: return state
    }
}