/* eslint-disable import/no-anonymous-default-export */
import {STORE_ONBOARDING_PAYLOAD, CLEAR_ONBOARDING_PAYLOAD} from '../actions/types';

const initialState = { onboarding_store: null }

export default function (state = initialState, action){
    switch(action.type){
        case STORE_ONBOARDING_PAYLOAD:
            return {
                ...state,
                onboarding_store: action.payload
            }
        case CLEAR_ONBOARDING_PAYLOAD:
            return {
                ...state,
                onboarding_store: null
            }
        default: return state
    }
}