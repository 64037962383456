import { combineReducers } from 'redux';

import authorization from './authorization';
import uioptions from './uioptions';
import getLimitsReducer from './getLimits';
import remitone from './remitOne';
import transactionCharges from './transactionCharges';
import newBeneficiaryReducer from './newBeneficiary';
import changePasswordReducer from './changePassword';
import resetPasswordReducer from './resetPassword';
import updateBeneficiaryReducer from './updateBeneficiary';
import getBeneficiaries from './allBeneficiaries';
import getSingleBeneficiaryReducer from './getSingleBeneficiary';
import newTransactionReducer from './newTransaction';
import getTransactions from './getTransactions';
import getSingleTransactionReducer from './getSingleTransaction';
import updateMyProfile from './updateMyProfile';
import confirmTransactionReducer from './confirmTransaction';
import onboardingPayload from './onboardingPayload';
import clearTransactionReducer from './clearTransaction';
import upgrdProfile from './upgradeProfile';
import myProfile from './getProfile';
import initPymnt from './initiatePayment';
import pollPymnt from './pollPayment';
import searchRemitterReducer from './searchRemitter';
import sendOTPReducer from './sendOTP';
import verifyOTPReducer from './verifyOTP';
import onboardRemitterReducer from './onboardRemitter';
import awaitPaymentReducer from './awaitPaymentReducer';

export default combineReducers({
  authorization,
  uioptions,
  getLimitsReducer,
  remitone,
  transactionCharges,
  newBeneficiaryReducer,
  changePasswordReducer,
  resetPasswordReducer,
  updateBeneficiaryReducer,
  getSingleBeneficiaryReducer,
  getBeneficiaries,
  newTransactionReducer,
  getTransactions,
  getSingleTransactionReducer,
  updateMyProfile,
  confirmTransactionReducer,
  onboardingPayload,
  clearTransactionReducer,
  upgrdProfile,
  initPymnt,
  pollPymnt,
  myProfile,
  searchRemitterReducer,
  sendOTPReducer,
  verifyOTPReducer,
  onboardRemitterReducer,
  awaitPaymentReducer
});