/* eslint-disable import/no-anonymous-default-export */
import {CLEAR_TRANSACTION_LOADING, CLEAR_TRANSACTION_PAYLOAD, SET_CLEAR_TRANSACTION_ERROR, CLEAR_TRANSACTION_PAYLOAD_ERROR, SET_CLEAR_TRANSACTION_STATE } from '../actions/types';

const initialState = {clear_trans: null, clear_trans_loading: false, clear_trans_error: null};

export default function (state = initialState, action){
    switch (action.type) {
        case CLEAR_TRANSACTION_LOADING:
            return {
                ...state, clear_trans_loading: true, clear_trans_error: null
            }
        case CLEAR_TRANSACTION_PAYLOAD:
            return {
                ...state, clear_trans: action.payload, clear_trans_loading: false, clear_trans_error: null
            }
        case SET_CLEAR_TRANSACTION_ERROR:
            return {
                ...state, clear_trans_loading: false, clear_trans_error: action.payload
            }
        case CLEAR_TRANSACTION_PAYLOAD_ERROR:
            return {
                ...state, clear_trans_loading: false, clear_trans_error: null
            }
        case SET_CLEAR_TRANSACTION_STATE:
            return {
                ...state, clear_trans: null, clear_trans_loading: false, clear_trans_error: null
            }
        default: return state 
    }
}