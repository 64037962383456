/* eslint-disable import/no-anonymous-default-export */
import {CLEAR_ONBOARD_REMITTER, CLEAR_ONBOARD_REMITTER_ERROR, ONBOARD_REMITTER_LOADING, ONBOARD_REMITTER_ERROR, ONBOARD_REMITTER} from '../actions/types';

const initialState = {
    onboard_remitter_data: null,
    onboard_remitter_loading: false,
    onboard_remitter_error: null
}

export default function (state = initialState, action){
    switch (action.type) {
        case CLEAR_ONBOARD_REMITTER:
            return {
                ...state,
                onboard_remitter_data: null,
                onboard_remitter_loading: false,
                onboard_remitter_error: null
            }
        case CLEAR_ONBOARD_REMITTER_ERROR:
            return {
                ...state,
                onboard_remitter_loading: false,
                onboard_remitter_error: null
            }
        case ONBOARD_REMITTER_LOADING:
            return {
                ...state,
                onboard_remitter_loading: true,
                onboard_remitter_error: null
            }
        case ONBOARD_REMITTER_ERROR:
            return {
                ...state,
                onboard_remitter_loading: false,
                onboard_remitter_error: action.payload
            }
        case ONBOARD_REMITTER:
            return {
                ...state,
                onboard_remitter_data: action.payload,
                onboard_remitter_loading: false,
                onboard_remitter_error: null
            }
        default:
            return state;
    }
}