/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useRef } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import logo from "assets/logo0.png";
import { FiFacebook, FiTwitter, FiYoutube, FiInstagram, FiLinkedin, FiPower } from "react-icons/fi";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { links, allLinks, webLinks } from "utils/constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { ConfirmPopup } from "primereact/confirmpopup";

const UnsignedHeader = (props) => {
	const history = useHistory();
	const [visible, setVisible] = useState(false);
	const hamburger = useRef(false);
	const hamburger1 = useRef(false);
	const { fb, tw, yt, ins, lin } = links;

	const { logout } = props;

	const handleLogout = () => {
		logout();
		history.push("/");
	};

	return (
		<div
			style={{
				zIndex: 100,
				boxShadow: "0px 3px 1px rgba(59, 54, 111, 0.25)",
			}}
		>
			<div className="bgYellow" style={{ position: "sticky", top: 0, zIndex: 100 }}>
				<div className="container d-none d-md-block">
					<div className="row py-2">
						<div className="col-11 col-xl-8 col-lg-7 col-md-9 col-sm-11  ">
							<a
								href="mailto:support@accessforex.com"
								target="_blank"
								className="me-3 me-sm-2 textBlue fw-bold"
								style={{ textDecoration: "none" }}
								rel="noreferrer"
							>
								support@accessforex.com
							</a>
							<a
								href="https://accessforex.com/help-and-support/"
								target="_blank"
								rel="noreferrer"
								className="me-3 me-sm-2 textBlue fw-bold"
								style={{ textDecoration: "none" }}
							>
								Help & Support
							</a>
							<a
								href="https://accessforex.com/blog-and-news/"
								target="_blank"
								rel="noreferrer"
								className="textBlue fw-bold"
								style={{
									textDecoration: "none",
								}}
							>
								Blog & News
							</a>
						</div>

						<div className="col-1 col-xl-4 col-lg-5 col-md-3 col-sm-1 text-end">
							<div className="p-0 ">
								<a
									href={fb}
									target="_blank"
									className="ms-3 textBlue"
									style={{ textDecoration: "none" }}
								>
									<FiFacebook />
								</a>
								<a
									href={tw}
									target="_blank"
									className="ms-3 textBlue"
									style={{ textDecoration: "none" }}
								>
									<FiTwitter />
								</a>
								<a
									href={yt}
									target="_blank"
									className="ms-3 textBlue"
									style={{ textDecoration: "none" }}
								>
									<FiYoutube />
								</a>
								<a
									href={ins}
									target="_blank"
									className="ms-3 textBlue"
									style={{ textDecoration: "none" }}
								>
									<FiInstagram />
								</a>
								<a
									href={lin}
									target="_blank"
									className="ms-3 textBlue"
									style={{ textDecoration: "none" }}
								>
									<FiLinkedin />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-md my-0">
				<div className="row">
					<div className="col-lg-3 col-md-3 col-sm-5 col-5 py-2">
						{props.layout === "auth" ? (
							<NavLink to="/register">
								<img src={logo} alt="ACCESS FOREX" className="px-0 logoDimensions" />
							</NavLink>
						) : (
							<NavLink to="/send-money">
								<img src={logo} alt="ACCESS FOREX" className="px-0 logoDimensions" />
							</NavLink>
						)}
					</div>

					<div className="col-lg-6 col-md-6 py-4 d-none d-md-block">
						{props.layout === "auth" && (
							<div className="text-center pt-3 mt-md-0 ">
								<a
									href="https://accessforex.com/about-us"
									className="textBlue me-2 me-lg-3 fw-bold d-none d-md-inline-block"
									target="_blank"
									style={{ fontSize: "14px", textDecoration: "none" }}
								>
									About Us
								</a>

								<a
									href="https://accessforex.com/collection-points/"
									className="textBlue me-2 me-lg-3 fw-bold d-none d-md-inline-block"
									target="_blank"
									style={{ fontSize: "14px", textDecoration: "none" }}
								>
									Collection Points
								</a>

								<a
									href="https://www.accessforex.com/how-it-works/"
									className="textBlue me-2 me-lg-3 fw-bold d-none d-md-inline-block"
									target="_blank"
									style={{ fontSize: "14px", textDecoration: "none" }}
								>
									How It Works
								</a>

								<a
									href="https://accessforex.com/contact-us/"
									className="textBlue fw-bold d-none d-md-inline-block"
									target="_blank"
									style={{ fontSize: "14px", textDecoration: "none" }}
								>
									Contact Us
								</a>
							</div>
						)}
					</div>

					<div className="col-lg-3 col-md-3 col-sm-7 col-7 py-4">
						{props.layout === "auth" ? (
							<div className="text-end pt-2">
								<Menu model={webLinks} popup ref={hamburger} />
								<Button
									icon="pi pi-bars"
									className="text-center p-button-success py-1 me-2 p-button-outlined d-inline-block d-md-none"
									onClick={(event) => hamburger.current.toggle(event)}
								/>
								<Link
									to="/"
									className="textBlue me-1 px-1 btn bgYellow text-center text-white"
									style={{ fontSize: "15px", textDecoration: "none" }}
								>
									Login
								</Link>
								<Link
									to="/register"
									className="textBlue px-1 btn bgYellow text-center text-white"
									style={{ fontSize: "15px", textDecoration: "none" }}
								>
									Register
								</Link>
							</div>
						) : (
							<div className="text-end pt-2">
								<Menu model={allLinks} popup ref={hamburger1} />
								<Button
									icon="pi pi-bars"
									className="text-center p-button-success py-1 me-2 p-button-outlined d-inline-block d-md-none"
									onClick={(event) => hamburger1.current.toggle(event)}
								/>

								<button
									type="button"
									className="btn text-white py-0 bgYellow py-1 px-2 "
									id="triggerLogout"
									onClick={() => setVisible(true)}
								>
									Exit <FiPower />
								</button>
								<ConfirmPopup
									target={document.getElementById("triggerLogout")}
									visible={visible}
									onHide={() => setVisible(false)}
									message="Are you sure you want to exit?"
									accept={handleLogout}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

// export default UnsignedHeader;

UnsignedHeader.propTypes = {
	logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(UnsignedHeader);
