import React from "react";
// import { ErrorMessage, useField } from 'formik';
import { InputText } from "primereact/inputtext";

const TextField = ({ ...props }) => {
	// const [field, meta] = useField(props);

	return (
		<>
			{props.floatingtext && (
				<div className="p-field mt-2">
					<p className="p-float-label mb-0">
						<InputText
							id={props.name}
							className={`p-d-block outlinenone`}
							{...props}
							style={{ width: "100%", border: "1px solid #3B366F" }}
						/>
						<label htmlFor={props.name}>{props.floatingtext}</label>
					</p>
				</div>
			)}
			{props.label && (
				<div className="p-field mt-2">
					<label htmlFor={props.name} className="p-d-block fw-bold">
						{props.label}
					</label>
					{props.iconright ? (
						<span className="p-input-icon-right mb-0" style={{ width: "100%" }}>
							<i className={`pi ${props.iconright}`} />
							<InputText
								id={props.name}
								className={`p-d-block outlinenone`}
								{...props}
								style={{ width: "100%", border: "1px solid #3B366F" }}
							/>
						</span>
					) : (
						<InputText
							id={props.name}
							className={`p-d-block outlinenone`}
							{...props}
							style={{ width: "100%", border: "1px solid #3B366F" }}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default TextField;
