import React from "react";

const RegularButton = ({ bg, ...props }) => {
	return (
		<button
			className={`btn fw-bold ${props.display} ${
				bg ? bg + " " + (props.color || "textBlue") : "bgBlue text-white"
			}  px-4 ${props.floatright === true && "float-end"}`}
			type={props.type}
			{...props}
		>
			{props.value}
		</button>
	);
};

export default RegularButton;
