import React from "react";
import { ErrorMessage, useField } from "formik";
import { Password as Pwd } from "primereact/password";
import { Divider } from "primereact/divider";
import "./Password.css";

const Password = ({ ...props }) => {
	const [field, meta] = useField(props);

	const passwordheader = <h6>Requirements</h6>;
	const passwordfooter = (
		<>
			<Divider />
			<ul className="list">
				<li>At least one uppercase</li>
				<li>At least one numeric value</li>
				<li>Minimum 8 characters</li>
			</ul>
		</>
	);

	return (
		<>
			{props.floatingtext && (
				<div className="p-field mt-2">
					<div className="p-float-label mb-0 rounded" style={{ border: "1px solid #3B366F" }}>
						<Pwd
							id={props.name}
							className={`p-d-block border-0 rounded ${
								meta.touched && meta.error && "is-invalid"
							}`}
							{...field}
							{...props}
							style={{ width: "100%" }}
							toggleMask
							header={passwordheader}
							footer={passwordfooter}
						/>
						<label htmlFor={props.name}>{props.floatingtext}</label>
					</div>
				</div>
			)}
			{props.label && (
				<div className="p-field mt-2">
					<label htmlFor={props.name} className="p-d-block fw-bold">
						{props.label}
					</label>
					{props.iconright ? (
						<span className="p-input-icon-right mb-0" style={{ width: "100%" }}>
							<i className={`pi ${props.iconright}`} />
							<Pwd
								id={props.name}
								className={`p-d-block rounded ${
									meta.touched && meta.error && "is-invalid"
								}`}
								{...field}
								{...props}
								style={{ width: "100%", border: "1px solid #3B366F" }}
								toggleMask
								header={passwordheader}
								footer={passwordfooter}
							/>
						</span>
					) : (
						<Pwd
							id={props.name}
							className={`p-d-block rounded ${meta.touched && meta.error && "is-invalid"}`}
							{...field}
							{...props}
							style={{ width: "100%", border: "1px solid #3B366F" }}
							toggleMask
							header={passwordheader}
							footer={passwordfooter}
						/>
					)}
				</div>
			)}
			<ErrorMessage component="div" name={field.name} className="ms-2 mt-0 text-danger" />
		</>
	);
};

export default Password;
