import React from "react";
import { Footer, SignedHeader } from "components";
import "./ShareWithFriend.css";
import {
  FiCopy,
  FiFacebook,
  FiTwitter,
  FiLinkedin,
  FiInstagram,
  FiMail,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

const copyToClipBoard = () => {
  let copyText = document.getElementById("textToSelectField");
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  document.execCommand("copy");
  alert("Copied the text: " + copyText.value);
};

const ShareWithFriend = (props) => {

  const { user } = props.authorization
  const { uioptions } = props;

  return (
    <>
      <SignedHeader />
      <div className="container mt-2">
        <div className="row">
          <div className="col-12">
            <h2 className="fw-bold mb-4">Share With a Friend</h2>

            <p>
              For every friend who joins Access Forex and creates an account,
              we’ll give away one free remit transaction for both of you.{" "}
            </p>

            <div className="input-group mb-4 w-50">
              <input
                id="textToSelectField"
                type="text"
                readOnly="true"
                className="form-control border-end-0"
                value="https://share.accessforex/qrt389012hroosr.12kla.0ss"
              />
              <span
                className="input-group-text bg-white border-start-0"
                style={{ cursor: "pointer" }}
                onClick={() => copyToClipBoard()}
              >
                <FiCopy /> &nbsp; &nbsp; <strong>Copy</strong>
              </span>
            </div>

            <h6 className="fw-bold">Other ways to share with your friends</h6>

            <div className="d-flex d-inline mb-4">
              <span
                className="rounded text-center border p-2 me-2"
                style={{
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  width: "40px",
                  height: "40px",
                }}
              >
                <FiTwitter />
              </span>
              <span
                className="rounded text-center border p-2 me-2"
                style={{
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  width: "40px",
                  height: "40px",
                }}
              >
                <FiFacebook />
              </span>
              <span
                className="rounded text-center border p-2 me-2"
                style={{
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  width: "40px",
                  height: "40px",
                }}
              >
                <FiLinkedin />
              </span>
              <span
                className="rounded text-center border p-2 me-2"
                style={{
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  width: "40px",
                  height: "40px",
                }}
              >
                <FiInstagram />
              </span>
              <span
                className="rounded text-center border p-2 me-2"
                style={{
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  width: "40px",
                  height: "40px",
                }}
              >
                <FiMail />
              </span>
            </div>

            <Link to="#" className="btn bgYellow rounded">
              Check status of all your invites
            </Link>
          </div>
        </div>
      </div>
      <Footer uioptions={uioptions} user={user} />
    </>
  );
};

// export default ShareWithFriend;

ShareWithFriend.propTypes = {
  uioptions: PropTypes.object.isRequired,
  authorization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  uioptions: state.uioptions,
  authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(ShareWithFriend);

