/* eslint-disable import/no-anonymous-default-export */
import {REMIT_ONE, REMIT_ONE_ERROR, CLEAR_REMIT_ONE_ERROR, REMIT_ONE_LOADING, CLEAR_REMIT_ONE} from '../actions/types';

const initialState = {
    remit_one: null, remit_one_error: null, remit_one_loading: false
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_REMIT_ONE_ERROR:
            return {
                ...state,
                remit_one_error: null, 
                remit_one_loading: false
            }
        case REMIT_ONE_ERROR:
            return {
                ...state,
                remit_one_error: action.payload ,
                remit_one_loading: false
            }
        case REMIT_ONE:
            return {
                ...state,
                remit_one: action.payload,
                remit_one_loading: false
            }
        case CLEAR_REMIT_ONE:
            return {
                ...state,
                remit_one: null, 
                remit_one_error: null,
                remit_one_loading: false
            }
        case REMIT_ONE_LOADING:
            return {
                ...state, 
                remit_one_loading: true, 
                remit_one_error: null,
            }
        default: return state
    }
}