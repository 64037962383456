import React, { useEffect } from "react";
import { Router, useHistory } from "react-router-dom";
import { Routing } from "components";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
// import { useClearCache } from "react-clear-cache";
// import { ClearCacheProvider, useClearCacheCtx, useClearCache } from 'react-clear-cache';
import LogRocket from "logrocket";
import { connect } from "react-redux";
import * as actions from "store/actions";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

function App(props) {
	const history = useHistory();
	// const { isLatestVersion, emptyCacheStorage } = useClearCache();
	// const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

	const { user } = props.authorization;
	const { profile } = props.myProfile;

	LogRocket.init("nrbabk/access-forex-front-end-v2");

	let userObject = profile ? profile.remitone : user ? user : null;

	if (userObject) {
		LogRocket.identify(userObject.remitter_id ?? userObject.email, {
			name: `${userObject.first_name} ${userObject.last_name}`,
			email: userObject.email
		});
	}

	// useEffect(() => {
	// 	Bugsnag.notify(new Error('Test error'))
	// },[])

	// BugSnag

	// if (userObject) {
	// 	Bugsnag.start({
	// 		apiKey: process.env.REACT_APP_BUGSNAG_KYC_KEY,
	// 		plugins: [new BugsnagPluginReact()],
	// 		onError: function (event) {
	// 			event.setUser(userObject.remitter_id ? userObject.remitter_id : null, userObject.email, `${userObject.firstname} ${userObject.lastname}`)
	// 		}
	// 	})
	// }else{
	// 	Bugsnag.start({
	// 		apiKey: '481caae4d0ebc90c9f392284dcfcecf4',
	// 		plugins: [new BugsnagPluginReact()]
	// 	})
	// }

	// // Function to clear complete cache data
	// const clearCacheData = () => {
	// 	caches.keys().then((names) => {
	// 		names.forEach((name) => {
	// 			caches.delete(name);
	// 		});
	// 	});
	// 	console.log('Complete Cache Cleared')
	// };
	

	// useEffect(() => {
	// 	if (!isLatestVersion) {
	// 		console.log("Different version detected.");
	// 		clearCacheData();
	// 		emptyCacheStorage();
	// 	} else {
	// 		console.log("Version is up to date.");
	// 		clearCacheData();
	// 	}
	// }, []);


	// const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

	

	return (
		<div className="App">
			<Router history={history}>
				<Routing />
			</Router>
		</div>
	);
}

const mapStateToProps = (state) => ({
	myProfile: state.myProfile,
	authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(App);
