import React from "react";
import { ErrorMessage, useField } from "formik";

const Select = ({ options, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<div>
			<div className="mt-4">
				<label htmlFor={props.name} className="form-label fw-bold">
					{props.label}
				</label>
				<select
					className={`form-select ${meta.touched && meta.error && "is-invalid"}`}
					{...field}
					{...props}
					style={{ width: "100%", border: "1px solid #3B366F" }}
				>
					{!props.preselect && <option label={props.optionlabel || props.label}></option>}

					{options &&
						options.map((option, key) => {
							return (
								<option key={key} value={option.id}>
									{option.name}
								</option>
							);
						})}
				</select>
			</div>
			<ErrorMessage component="div" name={field.name} className="ms-2 mt-0 text-danger" />
		</div>
	);
};

export default Select;
