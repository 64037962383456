/* eslint-disable import/no-anonymous-default-export */
import {CLEAR_CHECK_AWAIT_PAYMENT, CLEAR_CHECK_AWAIT_PAYMENT_ERROR, CHECK_AWAIT_PAYMENT_LOADING, CHECK_AWAIT_PAYMENT_ERROR, CHECK_AWAIT_PAYMENT} from '../actions/types';

const initialState = {
    await_payment: null,
    await_payment_error: null,
    await_payment_loading: false
}

export default function (state = initialState, action){
    switch (action.type) {
        case CLEAR_CHECK_AWAIT_PAYMENT:
            return {
                ...state,
                await_payment: null,
                await_payment_error: null,
                await_payment_loading: false
            }
        case CLEAR_CHECK_AWAIT_PAYMENT_ERROR:
            return {
                ...state,
                await_payment_error: null,
                await_payment_loading: false
            }
        case CHECK_AWAIT_PAYMENT_LOADING:
            return {
                ...state,
                await_payment_error: null,
                await_payment_loading: true
            }
        case CHECK_AWAIT_PAYMENT_ERROR:
            return {
                ...state,
                await_payment_error: action.payload,
                await_payment_loading: false
            }
        case CHECK_AWAIT_PAYMENT:
            return {
                ...state,
                await_payment: action.payload,
                await_payment_error: null,
                await_payment_loading: false
            }
        default: return state;
    }
}