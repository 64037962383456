/* eslint-disable import/no-anonymous-default-export */
import {FETCH_UIOPTIONS_LOADING, FETCH_UIOPTIONS, FETCH_UIOPTIONS_ERROR, CLEAR_FETCH_UIOPTIONS, CLEAR_FETCH_UIOPTIONS_ERROR} from '../actions/types';

const initialState = {
    ui: null, ui_loading: false, ui_error: null
}

export default function (state = initialState, action){
    switch(action.type){
        case FETCH_UIOPTIONS_LOADING:
            return {
                ...state,
                ui_loading: true,
                ui_error: null
            }
        case FETCH_UIOPTIONS_ERROR:
            return {
                ...state,
                ui_loading: false,
                ui_error: action.payload
            }
        case FETCH_UIOPTIONS:
            return {
                ...state,
                ui: action.payload,
                ui_loading: false,
                ui_error: null
            }
        case CLEAR_FETCH_UIOPTIONS:
            return {
                ...state,
                ui: null, 
                ui_loading: false, 
                ui_error: null
            }
        case CLEAR_FETCH_UIOPTIONS_ERROR:
            return {
                ...state,
                ui_error: null,
            }
        default: return state 
    }
}