import React from "react";

const RadioButton = ({ ...props }) => {
  return (
    <>
      <div
        className="form-check rounded mt-2"
        style={{ border: "1px solid #3B366F" }}
      >
        <div className="className px-2">
          <input
            className={`form-check-input my-2`}
            type="radio"
            id={props.id}
            {...props}
          />
          <label
            className="form-check-label py-2 ps-2"
            htmlFor={props.id}
            style={{ borderLeft: "1px solid #3B366F" }}
          >
            {props.label}
          </label>
        </div>
      </div>
    </>
  );
};

export default RadioButton;
