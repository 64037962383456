import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
	TextField,
	SelectWithImages,
	Password,
	CheckBox,
	ButtonLoader,
	Error,
	Select,
	PageLoader,
} from "components";
import { AuthLayout } from "layouts";
import * as actions from "store/actions";

const Register = (props) => {
	const history = useHistory();
	const [countryName, setCountryName] = useState("");
	const [error, setError] = useState("");
	const [regCountries, setRegCountries] = useState();
	const [country, setCountry] = useState(null);
	const [, setProcessing] = useState(false);
	const { ui, ui_loading } = props.uioptions;
	const { user, user_loading, user_error } = props.authorization;

	const { getUIoptions, basicRegistration, clearBasicRegistrationError, clearUIoptionsError } =
		props;

	const initialValues = {
		country: "",
		email: "",
		password: "",
		subscribe: false,
	};

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Register";
		return () => {
			subscribe = false;
		};
	}, []);

	useEffect(() => {
		user && history.push("/send-money");
		return () => {
			clearBasicRegistrationError();
		};
	}, [clearBasicRegistrationError, user, history]);

	useEffect(() => {
		getUIoptions();
	}, [getUIoptions]);

	useEffect(() => {
		const time_id = setInterval(() => {
			user_error && clearBasicRegistrationError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [user_error, clearBasicRegistrationError]);

	useEffect(() => {
		let newCountriesArray = [];
		ui &&
			ui.result.countries.source.forEach((item) => {
				newCountriesArray.push({ id: item.code, name: item.name });
			});
		setRegCountries(newCountriesArray);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const registerSchema = Yup.object().shape({
		email: Yup.string().email("Invalid email address").required().label("Email address"),
		password: Yup.string()
			.matches(/([A-Z])/, "Invalid password, at least 1 capital letter expected")
			.min(8, "Invalid password. It should have more than 7 characters")
			.matches(/([0-9])/, "Invalid password, at least 1 numeric value expected")
			.required("Password is required"),
	});

	const handleRegister = (formData) => {
		if (country && country.code !== "") {
			formData.country = country.code;
			setProcessing(true);
			basicRegistration(formData);
			clearBasicRegistrationError();
			setError("");
		} else {
			setError("Country is required");
		}
	};

	let content = ui_loading ? (
		<PageLoader />
	) : (
		<AuthLayout
			uioptions={props.uioptions}
			clearUIoptionsError={clearUIoptionsError}
			pagetitle="SIGN UP"
		>
			{
				<Formik
					initialValues={initialValues}
					validationSchema={registerSchema}
					onSubmit={(values, { setSubmitting }) => {
						setSubmitting(true);
						handleRegister(values);
						setSubmitting(false);
					}}
				>
					{(formik) => (
						<Form>
							{user_error && user_error.map((err) => <Error key={err.error} error={err.error} />)}
							<div className="d-none d-md-block">
								{ui !== null && ui_loading === false && (
									<SelectWithImages
										countryName={countryName}
										setCountryName={setCountryName}
										country={country}
										setCountry={setCountry}
										name="country"
										onChange={(e) => formik.handleChange(e)}
										countries={ui && ui.result.countries.source}
									/>
								)}
								{error && <span className="text-danger">{error}</span>}
							</div>

							<div className="d-block d-md-none">
								{ui !== null && ui_loading === false && (
									<Select
										options={regCountries}
										name="country"
										label="Select Country"
										onChange={(e) => {
											formik.handleChange(e);
											setCountry({ code: e.target.value });
										}}
									/>
								)}
								{error && <span className="text-warning">{error}</span>}
							</div>

							<TextField label="Email*" name="email" />
							<Password label="Password*" name="password" type="password" />
							<div className="mt-3">
								<CheckBox
									name="subscribe"
									label="Subscribe to receive exclusive offers & services from us."
								/>
							</div>

							<div className="d-grid gap-2 mt-3">
								{user_loading ? (
									<ButtonLoader />
								) : (
									<button className="btn btn-primary bgBlue" type="submit">
										<i className="pi pi-lock"></i> &nbsp;Sign Up
									</button>
								)}
							</div>
							<p className="text-start mt-3 mb-1">
								<Link
									to="/"
									style={{
										textDecoration: "none",
										color: "#3B366F",
									}}
								>
									Already a member? <strong>Login</strong>
								</Link>
							</p>
						</Form>
					)}
				</Formik>
			}
		</AuthLayout>
	);

	return <>{content}</>;
};

Register.propTypes = {
	authorization: PropTypes.object.isRequired,
	uioptions: PropTypes.object.isRequired,
	getUIoptions: PropTypes.func.isRequired,
	basicRegistration: PropTypes.func.isRequired,
	clearUIoptionsError: PropTypes.func.isRequired,
	clearBasicRegistrationError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	authorization: state.authorization,
	uioptions: state.uioptions,
});

export default connect(mapStateToProps, actions)(Register);
