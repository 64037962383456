/* eslint-disable import/no-anonymous-default-export */
import { SET_TRANSACTION_CHARGES_LOADING, SET_TRANSACTION_CHARGES_ERROR, TRANSACTION_CHARGES, CLEAR_TRANSACTION_CHARGES_ERROR, CLEAR_TRANSACTION_CHARGES} from '../actions/types';

const initialState = { transaction_charges_data: null, transaction_charges_loading: false, transaction_charges_error: null }

export default function (state = initialState, action){
    switch(action.type){
        case SET_TRANSACTION_CHARGES_LOADING:
            return {
                ...state, transaction_charges_error: null, transaction_charges_loading: true
            }
        case SET_TRANSACTION_CHARGES_ERROR:
            return {
                ...state, transaction_charges_error: action.payload, transaction_charges_loading: false
            }
        case TRANSACTION_CHARGES:
            return {
                ...state, transaction_charges_error: null, transaction_charges_loading: false, transaction_charges_data: action.payload
            }
        case CLEAR_TRANSACTION_CHARGES_ERROR:
            return {
                ...state, transaction_charges_error: null, transaction_charges_loading: false
            }
        case CLEAR_TRANSACTION_CHARGES:
            return {
                transaction_charges_data: null, transaction_charges_loading: false, transaction_charges_error: null
            }
        default: return state
    }
}