/* eslint-disable import/no-anonymous-default-export */
import {GET_TRANSACTIONS, CLEAR_GET_TRANSACTIONS, CLEAR_GET_TRANSACTIONS_ERROR, GET_TRANSACTIONS_ERROR, GET_TRANSACTIONS_LOADING} from '../actions/types';

const initialState = {
    transactions: null, transactions_loading: false, transactions_error: null
}

export default function (state = initialState, action){
    switch (action.type) {
        case GET_TRANSACTIONS:
            return {
                ...state, transactions: action.payload, transactions_loading: false, transactions_error: null
            }
        case CLEAR_GET_TRANSACTIONS:
            return {
                ...state, transactions: null, transactions_loading: false, transactions_error: null
            }
        case CLEAR_GET_TRANSACTIONS_ERROR:
            return {
                ...state, transactions_loading: false, transactions_error: null
            }
        case GET_TRANSACTIONS_ERROR:
            return {
                ...state, transactions_loading: false, transactions_error: action.payload 
            }
        case GET_TRANSACTIONS_LOADING:
            return {
                ...state, transactions_loading: true, transactions_error: null
            }
        default: return state 
    }
}